nav {
  width: 100%;
  height: 100%;
  background-color: #1a3535;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 0.7rem 1.7rem;
  z-index: 100;
  position: sticky;
  top: 0;
  bottom: 0rem;
  backdrop-filter: blur(15px);
}

nav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-brickRed);
  font-size: 1.5rem;
}

nav a:hover {
  background: rgba(0, 0, 0, 0.3);
}

nav a.active {
  background: var(--color-darkGreen);
}

/* Media query small device */
@media screen and (max-width: 600px) {
  nav {
    gap: 0.5rem;
  }
}
