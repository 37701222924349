.container.contact_container {
  display: flex;
  justify-content: center;
  gap: 5rem;
}

.contact_options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact_option {
  border: 2px solid var(--color-darkGreen);
  padding: 1.2rem;
  border-radius: 1rem;
  text-align: center;
  transition: var(--transition);
}

.contact_option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

.contact_option_icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

/* .contact_option_icon2 {
 color: #0e76a8;
} */

/* Form */
form {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  /* width: 100%; */
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid var(--color-darkGreen);
  resize: none;
  color: var(--color-black);
}

input:focus {
  background-color: var(--color-bg);
  border-color: var(--color-green);
}

.success-message {
  font-size: 1.2rem;
  color: var(--color-green);
  margin-top: 2rem;
}

/* Media query medium device */
@media screen and (max-width: 1024px) {
  .container.contact_container {
    flex-direction: column;
    align-items: center;
  }
  .contact_options {
    width: 50%;
  }
}

/* Media query small device */
@media screen and (max-width: 600px) {
  .contact_options {
    width: 100%;
  }
  form {
    width: 100%;
  }
}
