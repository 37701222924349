.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  min-height: 100vh;
  padding-bottom: 3rem;
}

.intro-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-right {
  flex: 1;
}

.intro-left-wrapper {
  /* padding: 3rem; */
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.intro-hello {
  font-size: 2rem;
  font-weight: 300;
}

.intro-name {
  color: var(--color-brickRed);
}

.intro-title {
  height: 50px;
  overflow: hidden;
}

.intro-title-wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-50px);
  }
}

.intro-title-item {
  height: 50px;
  font-size: 2rem;
  font-weight: 500;
  color: var(--color-green);
  display: flex;
  align-items: center;
}

.header_socials {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.5rem;
}

.header_socials a {
  color: var(--color-brickRed);
}

.intro-img {
  object-fit: contain;
  transform: scaleX(-1);
  padding: 3rem;
}

/* Media query medium device */
@media screen and (max-width: 1024px) {
  .intro {
    flex-direction: column;
  }
  .intro-left-wrapper {
    align-items: center;
    text-align: center;
    height: 100%;
  }
  .btn {
    margin-top: 3rem;
  }

  .header_socials {
    margin-top: 3rem;
  }
  .intro-title-item {
    justify-content: center;
  }
}

/* Media query small device */
@media screen and (max-width: 600px) {
  .intro {
    flex-direction: column;
    gap: 0;
  }
  .intro-left-wrapper {
    align-items: center;
    text-align: center;
    height: 100%;
    gap: 0rem;
  }

  .intro-title-item {
    font-size: 1.5rem;
  }
}
