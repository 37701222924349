.about_container{
  display: grid;
  grid-template-columns:1fr 1fr;
  place-items: center;
}

.about_me{
  width: 70%;
  aspect-ratio: 1/1;
  background: linear-gradient(45deg, transparent, var(--color-darkGreen), transparent);
  border-radius: 2rem;
  display: grid;
  place-items: center;
}

.about_me_image{
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
}

.about_content p{
  margin: 1.5rem;
}



/* Media query medium device */
@media screen and (max-width: 1024px){
  .about_container{
    grid-template-columns: 1fr;
    gap: 0;
  }
  .about_me{
    width: 40%;
    margin: 2rem auto 4rem;
  }
  .about_content{
    z-index: 5;
  }
 
}

/* Media query small device */
@media screen and (max-width: 600px){
  .about_me{
    width: 50%;
    margin: 0 auto 3rem;
  }
 }