.skills_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.skills_container > div {
  padding: 2.4rem 3rem;
  border-radius: 1rem;
  border: 2px solid var(--color-darkGreen);
  transition: var(--transition);
}

.skills_container > div:hover {
  box-shadow: 0 4px 5px 3px var(--color-darkGreen);
  cursor: default;
}

.skills_container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-brickRed);
  font-size: 1.2rem;
}

.skills_container > div h4 {
  color: var(--color-green);
  font-size: 1.5rem;
}

.skills_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.skills_details {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.skills_details_icon {
  margin-top: 0.5rem;
}

/* Media query medium device */
@media screen and (max-width: 1024px) {
  .skills_container {
    grid-template-columns: 1fr;
  }
  .skills_container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }
}

/* Media query small device */
@media screen and (max-width: 600px) {
  .skills_container {
    gap: 1rem;
  }
  .skills_container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
  .skills_container > div h4 {
    font-size: 1rem;
  }
}
