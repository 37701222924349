@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

*{
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root{
  --color-bg: #FFFFFF;
  --color-black:#1b1b22;
  --color-brickRed: #c23b20;
  --color-green: #33b0a1e0;
  --color-darkGreen: #156666;
  --color-blue: #314ECE;
  --color-yellow: rgb(223, 198, 53);
  --transition: all 400ms ease;

  --container-width-lg:75%;
  --container-width-md:86%;
  --container-width-sm:90%;
}

html{
  scroll-behavior: smooth;
}

::-webkit-scrollbar{
  display: none;
}

body{
  font-family: 'Poppins', sans-serif;
  background: var(--color-bg);
  color: var(--color-black);
}

/* General styles */
.container{
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,h2,h3,h4,h5{
  font-weight: 500;
}



 section{
   min-height: 100vh;
   padding-top: 5rem;
 }

 section > h2{
   text-align: center;   
   color: var(--color-brickRed);
   margin-bottom: 3rem;
   font-size: 3rem;
 }

 .text-light{
   color: var(--color-brickRed);
 }

 a{
   color: var(--color-brickRed);
   transition: var(--transition);
 }

 a:hover{
   color: var(--color-green);
 }

 .btn{
   width: max-content;
   display: inline-block;
   color: var(--color-brickRed);
   padding: 0.75rem 1.2rem;
   border-radius: 0.4rem;
   cursor: pointer;
   border: 1px solid var(--color-darkGreen);
   transition: var(--transition);
 }

 .btn:hover{
   background-color: var(--color-brickRed);
   color: var(--color-black);
   border-color: transparent;
 }

 .btn-primary{
   background: var(--color-black);
   color: var(--color-green);
 }

 img{
   display: block;
   width: 100%;
   object-fit: cover;
 }

 /* Media query for Tablet */

 @media screen and (max-width: 1024px){
   .container{
     width: var(--container-width-md);
   }
  
 }

 /* Media query for Mobile */
 @media screen and (max-width: 600px){
  .container{
    width: var(--container-width-sm);
  }
  section>h2{
    margin-bottom: 2rem;
    font-size: 2rem;
  }
}