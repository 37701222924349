.project_container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2.5rem;
}

.project_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #000000, #1a3535);
  padding: 1.3rem;
  border-radius: 0.5rem;
  transition: var(--transition);

}

.project_item:hover {
  box-shadow: 0 4px 5px 3px var(--color-darkGreen);
}

.project_item_image img {
  height: 250px;
}

.project_item_image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.project_item h3,
.project_item p {
  margin: 1rem 0 1rem;
}

.project_item h3 {
  color: #db616b;
}

.project_item p {
  color: var(--color-bg);
}

.project_item_cta {
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;
}

/* Media query medium device */
@media screen and (max-width: 1024px) {
  .project_container {
    grid-template-columns: 1fr 1fr;
  }
}

/* Media query small device */
@media screen and (max-width: 600px) {
  .project_container {
    grid-template-columns: 1fr;
  }
}
